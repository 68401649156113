import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";

type DebouncedFunction = (args?: unknown) => void;


interface IAccountResponse {
  account: IAccount;
  message: string;
  meta: IMeta;
}

interface IAccount {
  data: IAccountData[];
}

interface IAccountData {
  id: string;
  type: string;
  attributes: IAttributes;
}

interface IAttributes {
  id: number;
  email: string;
  profile_image: string | null;
  is_followed: boolean;
}

interface IMeta {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isSearchLoading: boolean;
  currPage: number;
  perPage: number;
  searchTerm: string;
  postId: number | null;
  lovesData: IAccountData[];
  totalCount: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LovesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationScreenNameMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isSearchLoading: false,
      perPage: 10,
      currPage: 1,
      searchTerm: "",
      postId: null,
      lovesData: [],
      totalCount: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (data) {
        this.setState({ postId: data.likeableId });
        this.getLovesData(data.likeableId);
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks();
    } else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getLovesListId: string = "";

  apiFailureCallBacks = () => {
    toast.error(configJSON.fetchErrorMsg);
    this.setState({ isLoading: false, isSearchLoading: false });
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: IAccountResponse) => {
    if (apiRequestCallId === this.getLovesListId) {
      this.setState({ isLoading: false, isSearchLoading: false, lovesData: responseJson?.account?.data, totalCount: responseJson?.meta?.total_count  });
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    const termType = this.props.navigation.getParam(configJSON.termParam);
    this.getLovesData(termType);
  }

  onCrossClick = () => {
    this.setState(
      {
        searchTerm: "",
        isSearchLoading: true
      },
      () => this.getLovesData(this.state.postId ?? 0)
    );
  };

  getLovesData = async (postId: number | string) => {

    const authToken = await getStorageData("token")
    this.setState({ isLoading: true });
    const { perPage, currPage, searchTerm } = this.state;

    this.getLovesListId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: configJSON.httpGetType,
        headers: { "token": authToken},
        endPoint: `${
          configJSON.getLovesEndpoint as string
        }?likeable_id=${postId}&likeable_type=${
          configJSON.likeTypePost
        }&email=${searchTerm}&page=${currPage}&per_page=${perPage}`,
      },
      runEngine
    );
  };

  debounce = <T extends DebouncedFunction>(
    func: (event: React.ChangeEvent<HTMLInputElement>) => void,
    delay: number
  ): T => {
    let timer: NodeJS.Timeout | undefined;
    return ((args: React.ChangeEvent<HTMLInputElement>) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => func(args), delay);
    }) as T;
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  callLovesApiWithSearch = async () => {
    this.setState({ isSearchLoading: true })
    this.getLovesData(this.state.postId ?? 0);
  };

  debounceInputChange = this.debounce(
    this.callLovesApiWithSearch,
    configJSON.debounceTime
  );


  handleViewMoreClick = () => {
    this.setState((prevState) => ({
      currPage: prevState.currPage + 1
    }), () => this.getLovesData(this.state.postId ?? 0));
  }


  handleProfileClick = (userId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.userProfileBasicBlock
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), userId);
    this.send(message);
  }

  // Customizable Area End
}
