Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "likeapost2";
exports.labelBodyText = "likeapost2 Body";
exports.termParam = "navigationBarTitleText";
exports.btnExampleTitle = "CLICK ME";


// Content type
exports.apiContentType = "application/json";


// Method type
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";
exports.httpGetType = "GET";


// API Endpoint
exports.postLikeEndpoint = "bx_block_like/likes";
exports.dislikePostEndpoint = "bx_block_like/likes";
exports.getLovesEndpoint = "bx_block_like/likes/post_or_comment_liked_users";


// Constants
exports.likeTypePost = "BxBlockPosts::Post";
exports.fetchErrorMsg = "Something went wrong!";
exports.globalErrorMessage = "Something went wrong!";
exports.deleteSuccesMsg = "Successfully destroy";
exports.loves = "Loves";
exports.debounceTime = 500;
exports.textType = "text";
exports.searchName = "search";
exports.searchPlaceHolder = "Search"
exports.outlined = "outlined";
exports.skeletonArr = [1, 2, 3, 4, 5];
exports.noUsersFound = "No users found";
exports.viewAll = "View more";
exports.userProfileBasicBlock = "UserProfileBasicBlock";

// Customizable Area End